import React from "react";
import Gallery from "react-photo-gallery";
import { photos } from "./photos.js";
import "./UniDetails.css";
import ReactPlayer from "react-player";
import homeVideo from "../../../assets/img/uni/04 Unikat/home.mp4";
import vid from "../../../assets/img/uni/04 Unikat/video.mp4";
import { Link } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop.js";

function UniDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a journey.
        </h4>
        <h5 className="is-size-5">
          and during my time at university I was challenged with the following
          projects
        </h5>
        <figure
          className="image"
          style={{ width: "15vw", margin: "3rem auto" }}
        >
          {/*
          <img
            src={require("../../../assets/img/uni/01 Seniorenrat/final.png")}
            alt=""
        />*/}
        </figure>
      </div>
      <div className="has-text-centered">
        <h4 className="is-size-4">Seniorenrat Hof</h4>
        <h5 className="is-size-5">Logo Design </h5>
        <br />
      </div>
      <section>
        <div className="container mt-4 is-size-5">
          <div className="columns">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p>
                The senior citizens' council called 'Seniorenrat Hof' wanted a
                Logo for their website, letters and business cards. Thus they
                approched students at Hof University to design one.
                <br />
                <br />
              </p>
              <p className="has-text-centered is-size-5">
                The following logos where my first designs.
              </p>
            </div>
          </div>
          <div className="columns is-mobile is-size-5">
            <div className="column  is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/01 Seniorenrat/concepts_opac.png")}
                  alt=""
                />
              </figure>
              <br />
              <p>
                The favorite one was the one representing all 11 members of the
                council as circles. Arranged in a curved shape to represent a
                council desk and the title "Seniorenrat Hof" representing the
                city approaching which they are helping.
              </p>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/01 Seniorenrat/v2.png")}
                  alt=""
                />
              </figure>
              <br />
              <p className="has-text-centered is-size-5">
                After a little fine tuning they were happy with the following
                logo.
              </p>
              <br />
              <figure
                className="image"
                style={{ width: "15vw", margin: "3rem auto" }}
              >
                <img
                  src={require("../../../assets/img/uni/01 Seniorenrat/final.png")}
                  alt=""
                />
              </figure>
              <br />
              <figure
                className="image"
                style={{
                  boxShadow: "0px 0px 4px grey",
                  width: "35vw",
                  margin: "3rem auto",
                }}
              >
                <img
                  src={require("../../../assets/img/uni/01 Seniorenrat/Visitenkarte.png")}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="is-divider"></div>
          <div className="has-text-centered">
            <h4 className="is-size-4">ChessVR: Mechapocalypse</h4>
            <h5 className="is-size-5">
              Texturing &amp; Environment Design, Poster Design{" "}
            </h5>
            <br />
            <br />
          </div>
          <div className="columns  is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p>
                Fellow students and I, had the crazy idea of playing chess with
                VR glasses. Oh and while we are at it, why not use mechs as
                chess characters.
                <br />I was responsible for creating the environment and the
                textures. For the game we user <b>Unity3D</b> and{" "}
                <b>Oculus Rift</b> glasses. For the textures I used{" "}
                <b>Substance Suite</b>. And for our presentation at{" "}
                <a
                  href="https://www.einstein1.net/event/gaming-concept-day/"
                  target="blank"
                >
                  <b>Gaming Concept Day @ Einstein1</b>
                </a>{" "}
                I was making an eye catching poster.
              </p>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/03 ChessVR/ChessVR-Poster.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/03 ChessVR/Screeshot.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <span>
                Note: As the time of taking those screenshots we weren't done
                modelling the mechs yet.
              </span>
              <br />
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/03 ChessVR/Screenshot_MapB_1.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="is-divider"></div>
          <div className="has-text-centered">
            <h4 className="is-size-4">Unikat</h4>
            <h5 className="is-size-5">Corporate Design &amp; Video</h5>
            <br />
          </div>
          <div className="columns is-mobile">
            <div className="column is-8-desktop is-offset-2-desktop is-full-mobile">
              <p>
                During my 4th semester, I found my <b>passion</b> for corporate
                design (CD). I really liked bringing the spirit of a product or
                a company to life with the design work I do. <br />
                <br />
                The following project 'Unikat' is a made up company that
                produces individual speakers. To achieve a good CD, I did these
                steps:
                <br />
                <br />
                {/*
                    Entwurf Leitbild bestehend aus: Vision, Mission und Werte
                    Herausarbeiten eines unique selling points
                    Zielgruppendefinition durch: GDPV-Merkmalen, Sinus-Milieus, Limbic-Types und natürlich Personas
                    Konkurrenzanalyse anhand der Leistung, Persönlichkeit, Nutzen und dem Kern der Marke
                    Definiton von Kommunikationinstrumente und Medien
                    Entwurf von Basiselementen und Wortmarke
                    Design von Kommunikationsmedien
                    Definieren des Style Guides und umsetzung als extra Webseite
                    Entwicklung der Unikat webseite
                */}
                <ul className="projectList is-size-5">
                  <li>
                    Drafting company mission statement consisting of: Vision,
                    mission and values
                  </li>
                  <li>Development of a unique selling point</li>
                  <li>
                    Target group definition through: GDPV characteristics, sinus
                    milieus, limbic types and of course personas
                  </li>
                  <li>
                    Competition analysis based on performance, personality,
                    benefits and the core of the brand
                  </li>
                  <li>Definition of communication instruments and media</li>
                  <li>Design of basic elements and word mark</li>
                  <li>Design of communication media</li>
                  <li>
                    Define the style guide and implement it as an additional web
                    page
                  </li>
                  <li>Development of the Unikat website</li>
                </ul>
              </p>
              <br />
              <div className="player-wrapper">
                <ReactPlayer
                  url={homeVideo}
                  playing={false}
                  controls={true}
                  muted="true"
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="player-wrapper">
                <ReactPlayer
                  url={vid}
                  playing={false}
                  controls={true}
                  muted="true"
                  width="100%"
                  height="auto"
                />
              </div>
            </div>
          </div>
          <div className="is-divider"></div>
          <div className="has-text-centered">
            <h4 className="is-size-4">Ferrari</h4>
            <h5 className="is-size-5">Infographics</h5>
            <br />
          </div>
          <br />
          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile">
              <p>
                I really enjoyed this course. We were supposed to do different
                types of infographics and as a car enthusiast I choose to use
                cars as the main topic.
              </p>
              <br />
              <figure
                className="image"
                style={{
                  width: "30%",
                  height: "auto",
                  float: "left",
                  marginRight: "5%",
                }}
              >
                <img
                  src={require("../../../assets/img/uni/05 Infografik/Bildstatistik.jpg")}
                  alt=""
                />
              </figure>
              <figure
                className="image"
                style={{ width: "30%", height: "auto", float: "left" }}
              >
                <img
                  src={require("../../../assets/img/uni/05 Infografik/Diagramm.jpg")}
                  alt=""
                />
              </figure>
              <figure
                className="image"
                style={{
                  width: "30%",
                  height: "auto",
                  float: "left",
                  marginLeft: "5%",
                }}
              >
                <img
                  src={require("../../../assets/img/uni/05 Infografik/Karte.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="is-divider"></div>
          <div className="has-text-centered">
            <h4 className="is-size-4">Momo's Adventure</h4>
            <h5 className="is-size-5">Programming</h5>
            <br />
          </div>
          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p>
                The first game I worked on. Made in <b>Unity3D</b>, I was
                responsible for <b>programming</b> the logic behind the
                mechanics.
              </p>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/06  Momo's Adventure/momotitle.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/06  Momo's Adventure/momocutscene.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/06  Momo's Adventure/momolevel.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <figure className="image">
                <img
                  src={require("../../../assets/img/uni/06  Momo's Adventure/momounderground.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="has-text-centered">
            <div className="is-divider"></div>
            <h4 className="is-size-4">campuls</h4>
            <h5 className="is-size-5"> Interface Design</h5>
            <br />
          </div>
          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p>
                Our university magazine{" "}
                <a
                  href="https://www.hof-university.de/ueber-uns/organisation/hochschulkommunikation/publikationen.html"
                  target="blank"
                >
                  <b>campuls</b>
                </a>{" "}
                is only available as print and pdf. During the course 'Interface
                and Interaction Design' students got the opportunity to design a
                website.
              </p>
              <br />
              <figure className="image" style={{ border: "1px solid grey" }}>
                <img
                  src={require("../../../assets/img/uni/07 campuls/start.jpg")}
                  alt=""
                />
              </figure>
              <br />
              <figure className="image" style={{ border: "1px solid grey" }}>
                <img
                  src={require("../../../assets/img/uni/07 campuls/article.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="has-text-centered">
            <div className="is-divider"></div>
            <h4 className="is-size-4">Hof erfahren</h4>
            <h5 className="is-size-5"> Photography</h5>
            <br />
          </div>
          <div className="columns">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p>
                Not every day you get to combine three of your passions for one
                project. This was one and hopefully not the last one.
                <br />
                Heading outside with the task of capturing my daily live outside
                of university. I used my passions for <b>photography</b>, my
                appreciation for <b>cycling</b> and my love for <b>nature</b> to
                capture the shots you see below. <br />
                If you are interested in more pictures taken by me check out my{" "}
                <Link to="/projects/personal">
                  <b>personal projects</b>
                </Link>{" "}
                or head over to my{" "}
                <a
                  href="https://www.instagram.com/maximilian_lindig/"
                  target="blank"
                >
                  <b>Instagram Account</b>
                </a>
                .
              </p>
              <br />
              <Gallery photos={photos} />
              <figure>
                <img
                  src={require("../../../assets/img/uni/08 Hof erfahren/_DSF6925.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default UniDetails;
