export const photos = [
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170506-_DSC004601.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170506-_DSC005902.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC064303.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC064804.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC067405.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC070306.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC070607.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170620-_DSC071208.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170624-_DSC074209.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/uni/08 Hof erfahren/20170624-_DSC078810.jpg"),
    width: 2,
    height: 3
  }
];
