import React from "react";
import ScrollToTop from "../../ScrollToTop.js";

function PortfolioDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a <b>journey</b>.
          <br />
          <br />
        </h4>
        <h5 className="is-size-5">
          And this portfolio was a way to learn new skills.{" "}
        </h5>
        <br />
        <br />
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            {" "}
            <p className="is-size-5">
              I did my initial sketches on my iPad and then started with some
              basic HTML and CSS. I used{" "}
              <a href="https://bulma.io/" target="blank">
                <b>Bulma</b>
              </a>{" "}
              as a CSS library instead of Bootstrap. I was curious how it would
              compete to the most popular library Bootstrap, which i've been
              using the most of the time. <br />
              <br />
              After the basic skeleton was done, I got started with implementing
              it in <b>React</b>. This is not really necessary, but I wanted the
              option to include a <b>headless CMS</b> in the future. I think
              thats a nice-to-have skill for the future and it also makes adding
              content easier for additions to the projects.
              <br />
              <br />
              Oh and Bulma is pretty intuitive and easy to use. I would
              recommend giving it a try :)
            </p>
          </div>
        </div>
      </div>
      <section>
        <div className="project-container container mt-4">
          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop has-text-left">
              <figure>
                <img
                  src={require("../../../assets/img/portfolio/ia_min.png")}
                  alt=""
                />
              </figure>
              <br />
              <figure>
                <img
                  src={require("../../../assets/img/portfolio/sketch_01.png")}
                  alt=""
                />
              </figure>
              <br />
              <figure>
                <img
                  src={require("../../../assets/img/portfolio/sketch_02.png")}
                  alt=""
                />
              </figure>
              <br />
              <figure>
                <img
                  src={require("../../../assets/img/portfolio/sketch_03.png")}
                  alt=""
                />
              </figure>
              <br />
              <figure>
                <img
                  src={require("../../../assets/img/portfolio/sketch_04.png")}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PortfolioDetails;
