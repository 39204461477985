import React from "react";
import { Link } from "react-router-dom";
import "./Projects.scss";

function Projects() {
  return (
    <React.Fragment>
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 className="is-size-4">
          Every project is not just a task, its a <b>journey</b>.
        </h4>
      </div>

      <section>
        <div className="project-container container mt-4">
          <div className="columns is-mobile">
            <div className="column">
              <div id="portfolio" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-bmw">
                  <h3 className="is-size-3 has-text-centered card-title">
                    My Portfolio
                  </h3>
                  {/* 
                  <h5 className="is-size-4 has-text-centered card-subtitle">
                    Freelance
                  </h5>
                  */}
                  <figure className="image poststamp is-64x64">
                    <img
                      alt="BMW"
                      src={require("../../assets/img/portfolio/favicon.png")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        A web portfolio is nowadays a must for every designer.
                      </p>
                      <p>
                        This is my take on a portfolio design, based on a{" "}
                        <b>sketch</b> and then implemented using <b>ReactJS</b>.
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Home office</p>
                      <p>Bad Steben</p>
                      <p>Germany</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/portfolio">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-portfolio"></div>
              </div>
            </div>
            <div className="column">
              <div id="bmw" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-bmw">
                  <h3 className="is-size-3 has-text-centered card-title">
                    UX-Designer
                  </h3>
                  <h5 className="is-size-4 has-text-centered card-subtitle">
                    Internship
                  </h5>
                  <figure className="image poststamp is-64x64">
                    <img alt="BMW" src={require("../../assets/img/BMW.svg")} />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        As a <b>car enthusiast</b> I dreamed about the
                        opportunity to work in the automobile industry.
                      </p>
                      <p>
                        On August 2019 this came true and I started my work at
                        the BMW plant in Regensburg. Besides <b>debugging</b>{" "}
                        and <b>designing</b>, my main contribution was{" "}
                        <b> implementing</b> a web app using Oracle <b>APEX</b>.
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office Address:</p>
                      <p>Herbert-Quandt-Allee</p>
                      <p>93055 Regensburg</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/bmw">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-bmw"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="project-container container mt-4">
          <div className="columns is-mobile">
            <div className="column">
              <div id="nexxt" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-nexxt">
                  <h3 className="is-size-3 has-text-centered card-title">
                    Web Designer
                  </h3>
                  <h5 className="is-size-4 has-text-centered card-subtitle">
                    Working Student
                  </h5>
                  <figure className="image poststamp is-64x64">
                    <img
                      alt="NEXXT"
                      src={require("../../assets/img/nexxt/NEXXT-Light.png")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        <b>NEXXT</b>, developed at Weber as a manufacturing
                        execution system (MES) for Extruder.
                      </p>
                      <p>
                        I was responsible for the <b>corporate design</b> and{" "}
                        <b>UI design</b>. Implemented in HTML, CSS and JS for
                        the MES. Part of my responsibility was also designing
                        and adding easy content editing for the <b>Wordpress</b>{" "}
                        product page.
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office Address:</p>
                      <p>Bamberger Str. 20</p>
                      <p>96317 Kronach</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/nexxt">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-nexxt"></div>
              </div>
            </div>
            <div className="column">
              <div id="weber" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-weber">
                  <h3 className="is-size-3 has-text-centered card-title">
                    UI Designer
                  </h3>
                  <h5 className="is-size-4 has-text-centered card-subtitle">
                    Part Time Job
                  </h5>
                  <figure className="image poststamp is-64x64">
                    <img
                      alt="Weber"
                      src={require("../../assets/img/Weber_logo.svg")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        Weber, <b>my first</b>
                      </p>
                      <ul className="projectList">
                        <li>industry work</li>
                        <li>my first sprints</li>
                        <li>my first HMI</li>
                        <li>touch UI design</li>
                      </ul>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office Address:</p>
                      <p>Bamberger Str. 20</p>
                      <p>96317 Kronach</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/weber">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-weber"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="project-container container mt-4">
          <div className="columns">
            <div className="column">
              <div id="hiwi" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-hiwi">
                  <h3 className="is-size-3 has-text-centered card-title">
                    Student Assistant
                  </h3>
                  <figure className="image poststamp is-64x64">
                    <img
                      alt="Hof"
                      src={require("../../assets/img/Hof-Uni-Logo-mini.png")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        During my studies, professors saw potential in me to
                        help <b>digitalize</b> some aspects of the university.
                      </p>
                      <p>
                        My work ranged from creating a <b>prototype</b> of web
                        app to making a video for safety instructions from{" "}
                        <b> pre to post production</b>.
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office Address:</p>
                      <p>Alfons-Goppel-Platz 1</p>
                      <p>95028 Hof</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/hiwi">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-hiwi"></div>
              </div>
            </div>
            <div className="column">
              <div id="uni" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-uni">
                  <h3 className="is-size-3 has-text-centered card-title">
                    Media Informatics Student
                  </h3>
                  <figure className="image poststamp is-64x64">
                    <img
                      alt="Hof"
                      src={require("../../assets/img/Hof-Uni-Logo-mini.png")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>
                        University aka the beginning of my caffeine addiction.
                      </p>
                      <p>
                        Thanks to <b>Club Mate</b> and my professors, I
                        developed <b>new skills</b> and became more fascinated
                        for <b>user centered design.</b>
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office Address:</p>
                      <p>Alfons-Goppel-Platz 1</p>
                      <p>95028 Hof</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/uni">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-uni"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="project-container container mt-4">
          <div className="columns">
            <div className="column is-offset-one-quarter-desktop is-half-desktop">
              <div id="personal" className="postcard is-4by3 is-mobile">
                <div className="postcard-back postcard-back-personal">
                  <h3 className="is-size-3 has-text-centered card-title">
                    Personal Projects{" "}
                  </h3>
                  <figure className="image poststamp is-64x64">
                    <img
                      alt=""
                      src={require("../../assets/img/portfolio/favicon.png")}
                    />
                  </figure>
                  <div
                    className="columns is-mobile"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="column">
                      <p>Personal projects aka spare time work.</p>
                      <p>
                        During my spare time, I either shut off my mind by{" "}
                        <b>hiking</b> and taking <b>photographs</b> or I go full
                        speed on personal projects. <br />
                        My mind is always busy with <b>new ideas</b> or
                        questions that need answers like:{" "}
                        <i>"What is a headless CMS and when do I use one?"</i>
                      </p>
                    </div>
                    <div className="is-divider-vertical"></div>

                    <div className="column postcard-address">
                      <p>Office address:</p>
                      <p>Preferably in nature</p>
                      <p>Planet Earth, Mars coming soon?</p>
                    </div>
                  </div>
                  <div className="has-text-centered click-details">
                    <Link to="/projects/personal">
                      <span role="img" aria-label="click-emoji">
                        👉{" "}
                      </span>
                      Click here for more details
                      <span role="img" aria-label="click-emoji">
                        {" "}
                        👈
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="postcard-front postcard-front-personal"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Projects;
