import React from "react";
import ScrollToTop from "../../ScrollToTop.js";

function WeberDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a<b> journey</b>.
          <br />
          <br />
        </h4>
        <h5 className="is-size-5">
          For Weber I had the opportunity to make a completely new HMI Concept
          for their Extruder.
        </h5>
      </div>
      <section>
        <div className="project-container container mt-4">
          <figure className="image brandLogo">
            <img
              src={require("../../../assets/img/weber/Weber_logo.svg")}
              alt=""
            />
          </figure>

          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p className="is-size-5">
                Sadly, I can't show you any images of the concept. But I can
                tell you about my work. <br />
                It consisted of collecting Information about the UI in use at
                the time. Figuring out pain points, getting information about
                future features and the direction the system is going to be
                developed to.
                <br />
                <br />
                The next step was finding the right layout and developing
                components step by step with weekly feedback of employees from
                different areas in the company. This was done in Adobe XD.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default WeberDetails;
