import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import vid from "../../../assets/img/nexxt/login.mp4";
import ScrollToTop from "../../ScrollToTop.js";

function NEXXTDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a <b>journey</b>.
          <br />
          <br />
        </h4>
        <h5 className="is-size-5">
          For NEXXT I had the opportunity to design the identity, UI of the MES
          and the product page.
        </h5>
      </div>
      <section>
        <div className="container mt-4">
          <figure className="image brandLogo">
            <img
              src={require("../../../assets/img/nexxt/NEXXT-Light.png")}
              alt=""
            />
          </figure>

          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p className="is-size-5">
                Weber Maschinenfabrik GmbH was developing a new manufacturing
                execution system (MES) called NEXXT. Since I already worked on a
                concept for their new HMI (
                <Link to="/projects/weber">read more here</Link>), I was
                challenged with the following tasks:
                <br />
                <br />
                <ul className="projectList">
                  <li>
                    Creating a (mostly) digital brand identity i.e. logo, color
                    scheme and ui elements
                  </li>
                  <li>Developing an additional product page</li>
                </ul>
                <br />
                Due to data protection, I cannot show photos of the MES here.
                But if you are curious, you can check out the{" "}
                <a
                  className="is-underlined"
                  target="blank"
                  href="https://www.nexxt365.de/cms/"
                >
                  product page
                </a>{" "}
                or take a closer look at the picture below to see someone use
                the MES 😉
              </p>
              <br />
              <figure
                className="image"
                style={{ width: "400px", height: "auto", margin: "0px auto" }}
              >
                <img
                  src={require("../../../assets/img/nexxt/brochure.png")}
                  alt=""
                />
              </figure>
            </div>
          </div>

          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile">
              <ReactPlayer
                url={vid}
                playing={true}
                controls={false}
                muted="true"
                width="100%"
                height="auto"
                loop={true}
              />
              <br />
              <figure className="image">
                <a href="https://www.nexxt365.de/cms/" target="blank">
                  <img
                    src={require("../../../assets/img/nexxt/nexxt.png")}
                    alt=""
                    style={{ border: "1px solid grey" }}
                  />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default NEXXTDetails;
