import React from "react";
import anime from "animejs/lib/anime.es.js";
import "./Footer.scss";

class Footer extends React.Component {
  state = {
    isOpen: false,
  };

  pulseIn = (sectionName) => {
    anime({
      targets: "#pulse-icon-" + sectionName,
      scale: 1.2,
      duration: 350,
      easing: "cubicBezier(0.42, 0.0, 0.58, 1.0)",
      direction: "normal",
    });
  };

  pulseOut = (sectionName) => {
    anime({
      targets: "#pulse-icon-" + sectionName,
      scale: 1,
      duration: 350,
      easing: "cubicBezier(0.42, 0.0, 0.58, 1.0)",
      direction: "normal",
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    let modalclassName = this.state.isOpen ? "modal is-active" : "modal";
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <h4 className="is-size-4 headline">Let's connect</h4>
          <span>
            Get in touch for opportunities or just to say hi!{" "}
            <span role="img" aria-label="hello">
              👋
            </span>
          </span>
          <br />
          <div className="social-section">
            <br />
            <div>
              <a
                id="social-section-a"
                href="mailTo:maximilian.lindig@gmail.com"
                target="blank"
                onMouseOver={() => this.pulseIn("a")}
                onMouseOut={() => this.pulseOut("a")}
              >
                <i
                  className="far fa-envelope"
                  aria-hidden="true"
                  id="pulse-icon-a"
                ></i>
              </a>
            </div>
            <div>
              <a
                id="social-section-b"
                href="https://www.linkedin.com/in/maxlindig"
                target="blank"
                onMouseOver={() => this.pulseIn("b")}
                onMouseOut={() => this.pulseOut("b")}
              >
                <i
                  className="fab fa-linkedin"
                  aria-hidden="true"
                  id="pulse-icon-b"
                ></i>
              </a>
            </div>
            <div>
              <a
                id="social-section-c"
                href="https://www.instagram.com/maximilian_lindig/"
                target="blank"
                onMouseOver={() => this.pulseIn("c")}
                onMouseOut={() => this.pulseOut("c")}
              >
                <i
                  className="fab fa-instagram"
                  aria-hidden="true"
                  id="pulse-icon-c"
                ></i>
              </a>
            </div>
          </div>
          <div className="has-text-centered">
            <button onClick={this.toggleModal} className="impressum">
              Impressum
            </button>

            <div id="impressum" className={modalclassName}>
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Impressum</p>
                  {/* <button
                    onClick={this.toggleModal}
                    className="delete"
                    aria-label="close"
                  ></button> */}
                </header>
                <section className="modal-card-body">
                  <h2>Legal Disclosure</h2>
                  Information in accordance with Section 5 TMG
                  <br />
                  <br />
                  Am Dörflas 3<br />
                  95138 Bad Steben
                  <br />
                  <h3>Contact Information</h3>
                  E-Mail:
                  <a href="mailto:maximilian.lindig@gmail.com">
                    maximilian.lindig@gmail.com
                  </a>
                  <br />
                  Internet address:
                  <a
                    href="https://maxlindig.github.io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://maxlindig.github.io/
                  </a>
                  <br />
                  <h3>Graphics and Image Sources</h3>
                  https://www.nexxt365.de/cms/
                  <br />
                  https://demosystem.nexxt365.de
                  <br />
                  https://www.ferrari.com/de-DE
                  <br />
                  https://fontawesome.com/license/free
                  <br />
                  https://www.hansweber.de
                  <br />
                  https://www.bmw.de/de/index.html
                  <br />
                  https://www.hof-university.de <br />
                  <br />
                  <h3>Disclaimer</h3>
                  Accountability for content
                  <br />
                  The contents of our pages have been created with the utmost
                  care. However, we cannot guarantee the contents' accuracy,
                  completeness or topicality. According to statutory provisions,
                  we are furthermore responsible for our own content on these
                  web pages. In this matter, please note that we are not obliged
                  to monitor the transmitted or saved information of third
                  parties, or investigate circumstances pointing to illegal
                  activity. Our obligations to remove or block the use of
                  information under generally applicable laws remain unaffected
                  by this as per §§ 8 to 10 of the Telemedia Act (TMG).
                  <br />
                  <br />
                  Accountability for links
                  <br />
                  Responsibility for the content of external links (to web pages
                  of third parties) lies solely with the operators of the linked
                  pages. No violations were evident to us at the time of
                  linking. Should any legal infringement become known to us, we
                  will remove the respective link immediately.
                  <br />
                  <br />
                  Copyright
                  <br />
                  Our web pages and their contents are subject to German
                  copyright law. Unless expressly permitted by law, every form
                  of utilizing, reproducing or processing works subject to
                  copyright protection on our web pages requires the prior
                  consent of the respective owner of the rights. Individual
                  reproductions of a work are only allowed for private use. The
                  materials from these pages are copyrighted and any
                  unauthorized use may violate copyright laws.
                </section>
                <footer className="modal-card-foot">
                  <button
                    onClick={this.toggleModal}
                    className="button is-outlined"
                  >
                    Close
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
