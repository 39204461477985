import React from "react";
import "./ToolBoxItem.css";

function ToolBoxItem(props) {
  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      <figure
        className="image is-64x64"
        title={props.title}
        data-tooltip={props.title}
      >
        <img
          alt={props.title}
          src={require(`../../assets/img/tools/${props.fileName}`)}
        />
      </figure>
    </a>
  );
}

export default ToolBoxItem;
