import React from "react";
import ScrollToTop from "../../ScrollToTop.js";

function HiwiDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a <b>journey</b>.
          <br />
          <br />
        </h4>
        <h5 className="is-size-5">
          And at my university, I had the opportunity to work with two different
          professors.
        </h5>
      </div>
      <section>
        <div className="project-container container mt-4">
          <figure className="image brandLogo">
            <img
              src={require("../../../assets/img/hiwi/Hof-Uni-Logo.png")}
              alt=""
            />
          </figure>

          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p className="is-size-5">
                One position was about doing a prototype of a website which is
                meant to be an online, always reachable source of the professors
                presentations. So she and the students can access her documents
                always and from everywhere.
                <br />
                <br />
                The other job was the digitalization of a yearly, mandatory
                safety instruction for students and employees of the university.
                So far it was done by employees and a power point presentation.
                That had to be done multiple times because there is no lecture
                hall for all students to fit in.
                <br />
                For the video, my colleague and I were responsible for
                collecting the information first, planning the story and shots,
                filming, cutting and also doing the voice over.
                <br />
                Now with the video, it can easily displayed and also watched
                from home.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default HiwiDetails;
