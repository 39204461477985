export const photosM3 = [
  {
    src: require("../../../assets/img/personal/m3/1.jpg"),
    width: 400,
    height: 711
  },
  {
    src: require("../../../assets/img/personal/m3/2.jpg"),
    width: 400,
    height: 711
  },
  {
    src: require("../../../assets/img/personal/m3/3.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../assets/img/personal/m3/4.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/personal/m3/5.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/personal/m3/6.jpg"),
    width: 4,
    height: 5
  },
  {
    src: require("../../../assets/img/personal/m3/7.jpg"),
    width: 4,
    height: 5
  },
  {
    src: require("../../../assets/img/personal/m3/8.jpg"),
    width: 1600,
    height: 1066
  },
  {
    src: require("../../../assets/img/personal/m3/9.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/personal/m3/10.jpg"),
    width: 1600,
    height: 1066
  },
  {
    src: require("../../../assets/img/personal/m3/11.jpg"),
    width: 4,
    height: 5
  },
  {
    src: require("../../../assets/img/personal/m3/12.jpg"),
    width: 4,
    height: 5
  },
  {
    src: require("../../../assets/img/personal/m3/13.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/personal/m3/14.jpg"),
    width: 2,
    height: 3
  },
  {
    src: require("../../../assets/img/personal/m3/15.jpg"),
    width: 4,
    height: 5
  },
  {
    src: require("../../../assets/img/personal/m3/16.jpg"),
    width: 1600,
    height: 666
  },
  {
    src: require("../../../assets/img/personal/m3/17.jpg"),
    width: 4,
    height: 5
  }
];
