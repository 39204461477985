import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";

function Navbar() {
  return (
    <nav className="custom-navbar">
      <div className="columns  is-mobile">
        <div id="nav-about" className="column">
          <Link to="/about">about</Link>
        </div>
        <div className="column has-text-centered">
          <Link className="brand" to="/">
            ML
          </Link>
        </div>
        <div id="nav-projects" className="column">
          <Link className="is-pulled-right" to="/projects">
            projects
          </Link>
          <a href="/" id="nav-menu" className="icon">
            <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
