import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import BMWDetails from "./components/Projects/BMW/BMWDetails";
import WeberDetails from "./components/Projects/Weber/WeberDetails";
import NEXXTDetails from "./components/Projects/NEXXT/NEXXTDetails";
import HiwiDetails from "./components/Projects/Hiwi/HiwiDetails";
import UniDetails from "./components/Projects/Uni/UniDetails";
import PortfolioDetails from "./components/Projects/Portfolio/PortfolioDetails";
import PersonalDetails from "./components/Projects/Personal/PersonalDetails";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

function App() {
  return (
    <Router>
      <div className="App theme-light">
        <Navbar />
        <ScrollUpButton EasingType="easeInSine" />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route path="/projects/bmw" children={<BMWDetails />} />
          <Route path="/projects/nexxt" children={<NEXXTDetails />} />
          <Route path="/projects/weber" children={<WeberDetails />} />
          <Route path="/projects/hiwi" children={<HiwiDetails />} />
          <Route path="/projects/uni" children={<UniDetails />} />
          <Route path="/projects/portfolio" children={<PortfolioDetails />} />
          <Route path="/projects/personal" children={<PersonalDetails />} />
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
