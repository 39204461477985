import React from "react";
import Gallery from "react-photo-gallery";
import { photosM3 } from "./photosM3.js";
import ScrollToTop from "../../ScrollToTop.js";

function PersonalDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="has-text-centered" style={{ margin: "4rem 0rem" }}>
        <h4 class="is-size-4">
          Every project is not just a task, its a journey.
        </h4>
        <h5 className="is-size-5">
          And some journeys start when others open Netflix.
        </h5>
      </div>
      <section>
        <div className="container mt-4">
          <div className="columns is-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <h4 className="is-size-4 has-text-centered">THE M3</h4>
              <br />
              <p>
                Combining my passion for photography and cars, I took a lot of
                pictures of my buddy{" "}
                <a
                  href="https://www.instagram.com/m3.ninety2"
                  target="blank"
                  className="has-text-weight-medium"
                >
                  @m3.ninety2
                </a>{" "}
                during 2019. So when his birthday arrived, I decided to put the
                best ones of the year into a professional photobook for him.
              </p>
              <br />
            </div>
          </div>
          <div className="columns">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile">
              <figure>
                <img
                  src={require("../../../assets/img/personal/m3/book.jpg")}
                  alt=""
                />
              </figure>
              <Gallery photos={photosM3} />
              <figure>
                <img
                  src={require("../../../assets/img/personal/m3/book_back.jpg")}
                  alt=""
                ></img>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PersonalDetails;
