import React from "react";
import ScrollToTop from "../../ScrollToTop.js";

function BMWDetails() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <div
        className="has-text-centered"
        style={{ margin: "4rem 0rem 2rem 0rem" }}
      >
        <h4 class="is-size-4">
          Every project is not just a task, its a <b>journey</b>.
        </h4>
      </div>
      <section>
        <div className=" container mt-4">
          <figure className="image brandLogo">
            <img src={require("../../../assets/img/bmw/BMW.svg")} alt="" />
          </figure>

          <div className="columns is-full-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <p className="is-size-5">
                At BMW I had the opportunity to design and develop web
                applications. My work at InnoLab4 consisted of creating
                concepts, designing and developing various web applications.
                These were all used in the Regensburg plant to make the work of
                the employees easier.
                <br />I even had the chance to create an app on my own from
                scratch to deployment and later on supporting the customer and
                doing bugfixes.
              </p>
              <br />
            </div>
          </div>
          <div className="columns is-full-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <h5 className="is-size-5 has-text-weight-bold">Design:</h5>
              <br />
              <ul className="projectList is-size-5">
                <li>
                  Design of logos and icons for several new apps in
                  consideration of the BMW corporate identity
                </li>
                <li>
                  Creation of loading screens and loading animations with HTML,
                  CSS and JS
                </li>
                <li>
                  Creation of illustrations for apps and videos in Illustrator
                </li>
                <li>
                  Implementation of prototypes and new UI/UX ideas in Adobe XD
                </li>
                <li>
                  Adaptation of existing app layouts including the addition of
                  new features
                </li>
                <li>
                  Retouching and image editing in Adobe Photoshop and Lightroom
                </li>
              </ul>
            </div>
          </div>
          <div className="columns is-full-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <h5 className="is-size-5 has-text-weight-bold">Development:</h5>
              <br />
              <ul className="projectList is-size-5">
                <li>
                  Independent planning and development of a new, plant-wide web
                  application in Oracle Apex
                </li>
                <li>
                  Support in the creation of a knowledge platform in Confluence
                </li>
                <li>
                  Analysis of the target groups and the context of use as well
                  as user survey
                </li>
                <li>Analysis of existing tools and processes</li>
                <li>
                  Bug fixing for existing applications and implementation of new
                  functions
                </li>
              </ul>
              <br />
              <span className="is-size-5">
                Organized in two-week sprints with help of Jira and regular
                stand ups and sprint reviews.
              </span>
            </div>
          </div>
          <div className="columns is-full-mobile">
            <div className="column is-offset-2-desktop is-8-desktop is-full-mobile has-text-left">
              <figure className="image">
                <img
                  src={require("../../../assets/img/bmw/photo_reduced_4by5.jpg")}
                  alt=""
                />
              </figure>
            </div>
          </div>

          <div className="is-size-5 has-text-centered">
            <br />
            For data protection reasons, no BMW internal images may be shown
            here.
            <br />
            For more informations about the department I worked in, check out
            their{" "}
            <a
              className="is-underlined"
              target="blank"
              href="https://www.bmwgroup-werke.com/regensburg/de/aktuelles/Industrie_40.html"
            >
              website
            </a>
            <br />
            <br />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BMWDetails;
