import React from "react";
import "./About.scss";
import { items } from "./items";
import ToolBoxItem from "./ToolBoxItem";

class About extends React.Component {
  state = {
    captions: [
      "Coffee lover",
      "Fast learner",
      "Communicative",
      "Creative",
      "Team Player",
      "Overthinker",
      "Perfectionist",
      "Passionate",
    ],
    currentCaption: "",
    currentCaptionIndex: 0,
  };

  componentDidMount() {
    this.setState({
      currentCaption: this.state.captions[0],
      currentCaptionIndex: 0,
    });
    this.interval = setInterval(this.toggleCurrentCaption, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  toggleCurrentCaption = () => {
    let length = this.state.captions.length;
    let curri = this.state.currentCaptionIndex;
    if (++curri >= length) {
      this.setState({
        currentCaption: this.state.captions[0],
        currentCaptionIndex: 0,
      });
    } else {
      let currentIndex = this.state.currentCaptionIndex;
      currentIndex = currentIndex + 1;
      this.setState({
        currentCaption: this.state.captions[currentIndex],
        currentCaptionIndex: currentIndex,
      });
    }
  };

  render() {
    let currentCaption = this.state.currentCaption;
    return (
      <div className="container about">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical is-3">
            <div className="tile is-child">
              <figure className="image is-me">
                <img
                  alt="me"
                  src={require("../../assets/img/me-polaroid.jpg")}
                />
                <div className="has-text-centered">
                  <p className="photo-caption">{currentCaption}</p>
                </div>
              </figure>
              <div className="contact-details">
                <h3 className="is-size-3 headline">Contact</h3>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Bad%20Steben%2C%20Bad%20Steben%2C%20BY&query_place_id=ChIJy1c3eZBroUcRwN9h8Ci3HQQ"
                      target="blank"
                    >
                      Bad Steben
                    </a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <a href="mailto:Maximilian.Lindig@gmail.com" target="blank">
                      E-Mail
                    </a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fab fa-linkedin-in"></i>
                    </span>
                    <a href="www.linkedin.com/in/maxlindig" target="blank">
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fab fa-xing"></i>
                    </span>
                    <a
                      href="https://www.xing.com/profile/Maximilian_Lindig"
                      target="blank"
                    >
                      Xing
                    </a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fab fa-github"></i>
                    </span>
                    <a href="https://github.com/MaxLindig" target="blank">
                      Github
                    </a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fab fa-instagram"></i>
                    </span>
                    <a
                      href="https://www.instagram.com/maximilian_lindig"
                      target="blank"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tile is-vertical is-9">
            <div
              className="tile is-parent is-vertical"
              style={{ paddingLeft: 0 }}
            >
              <div className="tile is-parent is-12">
                <div className="tile is-child has-text-justified tile-about">
                  <h3 className="is-size-3 headline">About Me</h3>
                  <p>
                    Hello, my name is{" "}
                    <span className="has-text-weight-medium">
                      Maximilian Lindig
                    </span>{" "}
                    but you can call me{" "}
                    <span className="has-text-weight-medium">Max</span> :) I've
                    made this website so you can get a sense of me and the work
                    I do. I'm 25 years old and studying Media Informatics at{" "}
                    <a
                      href="https://www.hof-university.de/"
                      target="blank"
                      className="is-underlined"
                    >
                      University of Applied Sciences Hof.
                    </a>
                  </p>
                </div>
                <div className="tile is-child tile-about">
                  <h3 className="is-size-3 headline">Education</h3>
                  <ul className="fa-ul edu-list">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-university"></i>
                      </span>
                      Media Informatics -
                      <span>
                        Hof University <br /> since Oct. 2016
                      </span>
                    </li>

                    <li>
                      <span className="fa-li">
                        <i className="fas fa-university"></i>
                      </span>
                      International Mechanical Engineering -
                      <span>
                        Hof University <br /> Oct. 2014 - Sep. 2016
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tile is-parent is-12">
                <div className="tile is-child has-text-justified tile-about">
                  <h3 className="is-size-3 headline">Skills</h3>
                  <p>
                    During my time at university as well as internship and part
                    time jobs, I enhanced my skills in the following topics:
                  </p>
                  <ul className="fa-ul skill-list">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-circle fa-xs"></i>
                      </span>
                      Corporate Design
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-circle fa-xs"></i>
                      </span>
                      Interface- und Interaction Design
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-circle fa-xs"></i>
                      </span>
                      User Experience Design
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-circle fa-xs"></i>
                      </span>
                      Responsive Web Design
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-circle fa-xs"></i>
                      </span>
                      Web Development
                    </li>
                  </ul>
                </div>

                <div className="tile is-child tile-about">
                  <h3 className="is-size-3 headline">Experience</h3>
                  <ul className="fa-ul exp-list">
                    <li>
                      <span className="fa-li">
                        <a
                          href="https://www.bmwgroup-werke.com/regensburg/de/aktuelles/Industrie_40.html"
                          target="blank"
                        >
                          <img
                            alt="BMW"
                            src={require("../../assets/img/BMW.svg")}
                          />
                        </a>
                      </span>
                      Internship as UX-Designer - BMW AG Plant Regensburg
                      <br />
                      Aug. 2019 - Feb.2020
                    </li>
                    <li>
                      <span className="fa-li">
                        <img
                          alt="Weber"
                          src={require("../../assets/img/Weber_logo.svg")}
                        />
                      </span>
                      Working Student as Web Design - Hans Weber Maschinenfabrik
                      GmbH
                      <br />
                      Aug. 2018 - Aug. 2019
                    </li>
                    <li>
                      <span className="fa-li">
                        <img
                          alt="Hof"
                          src={require("../../assets/img/Hof-Uni-Logo-mini.png")}
                        />
                      </span>
                      Student Assistent - Hof University
                      <br />
                      May 2018 - Jun. 2018 & Aug. 2018 - Dez. 2018
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tile toolbox-content">
              <div className="tile is-child">
                <h3 className="is-size-3 headline">Tool Box</h3>
                <div
                  className="icon-tools"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {items.map(function (item, idx) {
                    return (
                      <ToolBoxItem
                        key={idx}
                        title={item.title}
                        fileName={item.fileName}
                        url={item.url}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
