export const items = [
  {
    title: "Ableton",
    fileName: "ableton.png",
    url: "https://www.ableton.com/en/",
  },
  {
    title: "Adobe XD",
    fileName: "adobe-xd.svg",
    url: "https://www.adobe.com/de/products/xd.html",
  },
  {
    title: "Adobe After Effects",
    fileName: "After Effects.svg",
    url: "https://www.adobe.com/de/products/aftereffects.html",
  },
  {
    title: "Oracle Application Express",
    fileName: "apex.png",
    url: "https://apex.oracle.com/de/",
  },
  {
    title: "Mac OS",
    fileName: "Apple_logo_black.svg",
    url: "https://www.apple.com/macos/catalina/",
  },
  {
    title: "Bootstrap 4",
    fileName: "bootstrap_stack.png",
    url: "https://getbootstrap.com/",
  },
  {
    title: "Bulma",
    fileName: "bulma.svg",
    url: "https://bulma.io/",
  },
  {
    title: "Confluence",
    fileName: "confluence.svg",
    url: "https://www.atlassian.com/software/confluence",
  },
  {
    title: "CSS 3",
    fileName: "css.png",
    url: "https://en.wikipedia.org/wiki/Cascading_Style_Sheets",
  },
  {
    title: "Github",
    fileName: "github.png",
    url: "https://github.com/",
  },
  {
    title: "HTML 5",
    fileName: "html.png",
    url: "https://en.wikipedia.org/wiki/HTML5",
  },
  {
    title: "Adobe InDesign",
    fileName: "InDesign.svg",
    url: "https://www.adobe.com/de/products/indesign.html",
  },
  {
    title: "Java",
    fileName: "java.png",
    url: "https://en.wikipedia.org/wiki/Java_(programming_language)",
  },
  {
    title: "Jira",
    fileName: "jira.svg",
    url: "https://www.atlassian.com/software/jira",
  },
  {
    title: "JavaScript",
    fileName: "js.png",
    url: "https://en.wikipedia.org/wiki/JavaScript/",
  },
  {
    title: "Keyshot",
    fileName: "keyshot.png",
    url: "https://www.keyshot.com//",
  },
  {
    title: "Laravel",
    fileName: "laravel.png",
    url: "https://laravel.com/",
  },
  {
    title: "Adobe Lightroom",
    fileName: "Lightroom.svg",
    url: "https://www.adobe.com/de/products/photoshop-lightroom.html",
  },
  {
    title: "Maya",
    fileName: "maya.svg",
    url: "https://www.autodesk.com/products/maya/overview",
  },
  {
    title: "Oracle Database",
    fileName: "oracledb.png",
    url: "https://www.oracle.com/database/",
  },
  {
    title: "Adobe Photoshop",
    fileName: "Photoshop.svg",
    url: "https://www.adobe.com/sea/products/photoshop.html",
  },
  {
    title: "Adobe Premiere Pro",
    fileName: "Premiere Pro.svg",
    url: "https://www.adobe.com/de/products/premiere.html",
  },
  {
    title: "R (Studio)",
    fileName: "r.svg",
    url: "https://rstudio.com/",
  },
  {
    title: "Sketch",
    fileName: "sketch.svg",
    url: "https://www.sketch.com/",
  },
  {
    title: "Slack",
    fileName: "slack.svg",
    url: "https://slack.com/intl/de-de/?eu_nc=1",
  },
  {
    title: "Solid Edge",
    fileName: "solidedge.png",
    url: "https://solidedge.siemens.com/en/",
  },
  {
    title: "Substance Designer",
    fileName: "substancedesigner.png",
    url: "https://www.substance3d.com/products/substance-designer/",
  },
  {
    title: "Substance Painter",
    fileName: "substancepainter.png",
    url: "https://www.substance3d.com/products/substance-painter/",
  },
  {
    title: "Trello",
    fileName: "trello.png",
    url: "https://trello.com/",
  },
  {
    title: "Unity Engine",
    fileName: "unity.png",
    url: "https://unity.com/",
  },
  {
    title: "Windows",
    fileName: "windows.png",
    url: "https://en.wikipedia.org/wiki/Microsoft_Windows",
  },
  {
    title: "Zeplin",
    fileName: "zeplin.png",
    url: "https://zeplin.io/",
  },
];
